<template>
  <v-col cols="12">
    <div class="form-group" v-for="(detail,k) in details" :key="k" style="margin: 0">
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Claimant Name"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.claimant_name">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Claimant Email"
              color="primary"
              maxlength="50"
              :counter="50"
              @keyup="update"
              v-model="detail.claimant_email">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              maxlength="20"
              :counter="20"
              label="Claimant Mobile"
              color="primary"
              @keyup="update"
              v-model="detail.claimant_mobile">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Claimant Address"
              color="primary"
              maxlength="200"
              :counter="200"
              @keyup="update"
              v-model="detail.claimant_address">
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'case-claimants',
  props: ['caseClaimantsOld'],
  data() {
    return {
      details: [
        {claimant_name: '', claimant_email: '', claimant_mobile: '', claimant_address: ''}
      ]
    }
  },
  watch: {
    details: {
      handler(v) {
        this.$store.state.cases.caseClaimants = v;
      },
      deep: true
    },
    caseClaimantsOld: {
      handler() {
        if (this.caseClaimantsOld.length > 0) {
          this.details = this.caseClaimantsOld
        } else {
          this.details = [
            {claimant_name: '', claimant_email: '', claimant_mobile: '', claimant_address: ''}
          ]
        }
      },
      deep: true
    }
  },
  mounted() {
    // this.details = [
    //   {name: '', branch_name: '', branch_code: '', location: '', account_no: '', iban_no: ''}
    // ]
  },
  methods: {
    add() {
      this.details.push({claimant_name: '', claimant_email: '', claimant_mobile: '', claimant_address: ''});
    },
    update() {
    },
    remove(index) {
      this.details.splice(index, 1);
    },
  }
}
</script>